const mockCollectionData = [
    {
        id: "1xje8484",
        title: "Entities",
        keys: [
            "Entities Collection Mock Data",
            "RelationshipName",
            "EmailOne",
            "masterID",
            "master_ID",
            "MasterID",
            "HQCountry",
            "RelationshipManager",
            "ExternalSource",
            "BorrowerId",
            "DepositorName",
            "HQStreetAddress",
            "HQCity",
            "HQState",
            "MailingCity",
            "MailingState",
            "MailingPostalCode",
            "PhoneNumberOne",
            "PhoneNumberTwo",
            "PhoneNumberThree",
            "PhoneTypeTwo",
            "PhoneTypeThree",
            "EmailTwo",
            "EmailThree",
            "DepositorID",
            "AccountOne",
            "AccountTwo",
            "TIN",
            "EntityType",
            "CostCenter",
            "NACISCode",
            "PrimaryContact",
            "BorrowerRiskRating",
            "GuarantorRiskRating",
            "QPostalCode",
            "BorrowerName",
            "borrowerName",
            "MailingAddress",
            "Accoun_Three",
            "MailingA-dressCountry",
            "P_oneTypeOne"
       ] 
    },
    {
        id: "2",
        title: "Loans",
        keys: [
            "Loans Collection MockData",
            "RelationshipName",
            "EmailOne",
            "masterID",
            "master_ID",
            "MasterID",
            "HQCountry",
            "RelationshipManager",
            "ExternalSource",
            "BorrowerId",
            "DepositorName",
            "HQStreetAddress",
            "HQCity",
            "HQState",
            "MailingCity",
            "MailingState",
            "MailingPostalCode",
            "PhoneNumberOne",
            "PhoneNumberTwo",
            "PhoneNumberThree",
            "PhoneTypeTwo",
            "PhoneTypeThree",
            "EmailTwo",
            "EmailThree",
            "DepositorID",
            "AccountOne",
            "AccountTwo",
            "TIN",
            "EntityType",
            "CostCenter",
            "NACISCode",
            "PrimaryContact",
            "BorrowerRiskRating",
            "GuarantorRiskRating",
            "QPostalCode",
            "BorrowerName",
            "borrowerName",
            "MailingAddress",
            "Accoun_Three",
            "MailingAddressCountry",
            "P_oneTypeOne"
       ] 
    },
    {
        id: "3",
        title: "Reports",
        keys: [
            "Reports Collection Mock Data",
            "RelationshipName",
            "EmailOne",
            "masterID",
            "master_ID",
            "MasterID",
            "HQCountry",
            "RelationshipManager",
            "ExternalSource",
            "BorrowerId",
            "DepositorName",
            "HQStreetAddress",
            "HQCity",
            "HQState",
            "MailingCity",
            "MailingState",
            "MailingPostalCode",
            "PhoneNumberOne",
            "PhoneNumberTwo",
            "PhoneNumberThree",
            "PhoneTypeTwo",
            "PhoneTypeThree",
            "EmailTwo",
            "EmailThree",
            "DepositorID",
            "AccountOne",
            "AccountTwo",
            "TIN",
            "EntityType",
            "CostCenter",
            "NACISCode",
            "PrimaryContact",
            "BorrowerRiskRating",
            "GuarantorRiskRating",
            "QPostalCode",
            "BorrowerName",
            "borrowerName",
            "MailingAddress",
            "Accoun_Three",
            "MailingA-dressCountry",
            "P_oneTypeOne"
       ] 
    },
    {
        id: "4",
        title: "Other Collection",
        keys: [
            "Other collection Mock Data",
            "RelationshipName",
            "EmailOne",
            "masterID",
            "master_ID",
            "MasterID",
            "HQCountry",
            "RelationshipManager",
            "ExternalSource",
            "BorrowerId",
            "DepositorName",
            "HQStreetAddress",
            "HQCity",
            "HQState",
            "MailingCity",
            "MailingState",
            "MailingPostalCode",
            "PhoneNumberOne",
            "PhoneNumberTwo",
            "PhoneNumberThree",
            "PhoneTypeTwo",
            "PhoneTypeThree",
            "EmailTwo",
            "EmailThree",
            "DepositorID",
            "AccountOne",
            "AccountTwo",
            "TIN",
            "EntityType",
            "CostCenter",
            "NACISCode",
            "PrimaryContact",
            "BorrowerRiskRating",
            "GuarantorRiskRating",
            "QPostalCode",
            "BorrowerName",
            "borrowerName",
            "MailingAddress",
            "Accoun_Three",
            "MailingA-dressCountry",
            "PhoneTypeOne"
       ]    
    },
]

export default mockCollectionData